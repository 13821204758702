import { Main } from 'wikr-core-analytics';

export const handleLinkClick = (event) => {
    event.preventDefault();

    const clickedElement = event.target;

    if (!clickedElement.classList.contains('link')) return;

    const linkValue = clickedElement.getAttribute('data-custom-value');
    const linkHref = clickedElement.getAttribute('href');

    Main.customData('start_age_branches_google__click', { event_label: linkValue });

    window.location.href = linkHref;
};
