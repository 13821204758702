export const AVAILABLE_LOCALIZATIONS = ['de', 'en', 'es', 'fr', 'it', 'ja', 'pt'];
export const defaultLanguage = 'en';
export const defaultNavigatorLanguage = 'en-US';

export const domainConfig = {
    ONE_TRUST_STAGE_ID: 'f0d3f857-6a95-4118-a035-04c103c16ff8',
    ONE_TRUST_PROD_ID_NEW: 'e0e67f80-0224-4484-a654-08400e7189ab',
};

export const COOKIE_FOR_SKIP_START_PAGE = 'skipStartPage';
export const TARGETING_ACCEPT_COOKIE = 'C0004';
export const TARGETING_REJECT_COOKIE = 'C0001';
export const COOKIE_FOR_ACCEPT_ONE_TRUST = 'oneTrustAccepted';
export const UUID_LS_KEY = 'uuid';
export const PROD_DOMAIN = 'start.muscle-booster.io';
export const URL_PARAM_AWS_ID = 'track-id';
export const LS_KEY_AWS_ID = 'aws_id';
