import { getNavigatorLanguage } from "./getNavigatorLanguage";
import { AVAILABLE_LOCALIZATIONS, defaultLanguage } from "../../constants";

function getLocalizationFromAvailable() {
  const navigatorLanguage = getNavigatorLanguage();

  const isLanguageLocalized = AVAILABLE_LOCALIZATIONS.includes(navigatorLanguage);

  return isLanguageLocalized ? navigatorLanguage : null;
}

function getCurrentLocalization() {
  return window.location.pathname.split('/')[1];
}

export function setHtmlLang() {
  const language = getLocalizationFromAvailable();
  const languageFromURL = getCurrentLocalization();

  document.documentElement.setAttribute('lang', language || languageFromURL || defaultLanguage);
}
