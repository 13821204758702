import { getDomainId } from './helpers/getDomainId';
import { setHtmlLang } from './helpers/setHtmlLang';
import { COOKIE_FOR_ACCEPT_ONE_TRUST, TARGETING_ACCEPT_COOKIE, TARGETING_REJECT_COOKIE } from '../constants';
import { getLinkHref } from './helpers/getLinkHref';
import { injectHrefIntoLinks } from './helpers/injectHrefIntoLinks';
import { Main, systemNames } from 'wikr-core-analytics';
import config from '../config';

function loadOneTrust() {
    const domainId = getDomainId();

    if (domainId) {
        const { head } = document;

        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        script.charSet = 'UTF-8';
        script.setAttribute('data-document-language', 'true');
        script.setAttribute('async', '');
        script.setAttribute('data-domain-script', domainId);

        document.body.appendChild(script);

        window.addEventListener('error', function ({ error }) {
            const errorMessage = (error && error.stack) || '';

            const isOneTrustError = errorMessage.includes('otSDKStub.js') || errorMessage.includes('otBannerSdk.js');

            if (!window.OneTrust && isOneTrustError) {
                localStorage.setItem('isOneTrustError', `OneTrust: Invalid domain id - ${errorMessage}`);
            }
        });
    }

    if (!domainId) {
        localStorage.setItem('isOneTrustError', 'OneTrust Error: No ID for this domain');
    }
}

function getBannerElement() {
    new MutationObserver((_, observer) => {
        const bannerElement = document.getElementById('onetrust-banner-sdk');

        if (bannerElement) {
            window.OneTrust.OnConsentChanged((props) => {
                const consentDetail = props.detail;

                if (consentDetail.includes(TARGETING_ACCEPT_COOKIE)) {
                    localStorage.setItem(COOKIE_FOR_ACCEPT_ONE_TRUST, 'accept');

                    const currentHost = window.location.host;
                    const web2appHost = new URL(config.WEB2APP_URL).host;

                    Main.initSystems({
                        activeSystems: [
                            {
                                name: systemNames.ga,
                                id: config.GOOGLE_ID,
                                config: {
                                    googleAdsId: config.GOOGLE_ADS_ID,
                                    googleAdsShopId: config.GOOGLE_ADS_SHOP_ID,
                                    domains: [currentHost, web2appHost],
                                },
                            },
                        ],
                    });

                    injectHrefIntoLinks(getLinkHref());

                    return;
                }

                if (consentDetail.length === 1 && consentDetail[0] === TARGETING_REJECT_COOKIE) {
                    localStorage.setItem(COOKIE_FOR_ACCEPT_ONE_TRUST, 'reject');

                    injectHrefIntoLinks(getLinkHref());
                }
            });

            observer.disconnect();
        }
    }).observe(document.body, { childList: true, subtree: true });
}

function initOneTrust() {
    setHtmlLang();
    loadOneTrust();
    getBannerElement();
}

export default initOneTrust;
