import { Main, Provider, systemNames } from 'wikr-core-analytics';

import initOneTrust from './one-trust';

import { getAnalyticData } from './helpers/getAnalytic';
import { COOKIE_FOR_ACCEPT_ONE_TRUST, LS_KEY_AWS_ID, URL_PARAM_AWS_ID } from '../constants';
import config from '../config';
import { getLinkHref } from './helpers/getLinkHref';
import { injectHrefIntoLinks } from './helpers/injectHrefIntoLinks';
import { handleLinkClick } from './helpers/handleLinkClick';

function loadAnalytics() {
    const cardsWrapper = document.getElementById('cards');

    Main.initSystems({
        activeSystems: [
            {
                name: systemNames.amazon,
                id: config.AMAZON_ID,
                config: {
                    releaseDate: config.RELEASE_DATE,
                    streamName: config.AWS_DELIVERY_STREAM_NAME,
                    envMode: config.ENV,
                },
            },
        ],
    });

    Provider.provideData({
        country: async () => 'US',
        abTestName: async () => 'default_ab_test_name',
    });

    getAnalyticData().then((analyticData) => {
        localStorage.setItem(LS_KEY_AWS_ID, analyticData.aws_id);

        injectHrefIntoLinks(getLinkHref());

        cardsWrapper.addEventListener('click', handleLinkClick);
    });

    Main.customData('start_session');
    Main.customData('start_age_branches_google__screen__load');
}

window.addEventListener('pageshow', () => {
    localStorage.setItem(COOKIE_FOR_ACCEPT_ONE_TRUST, 'accept');

    loadAnalytics();
    initOneTrust();
});
