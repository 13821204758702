import { domainConfig, PROD_DOMAIN } from '../../constants';

export function getDomainId() {
    switch (document.location.host) {
        case PROD_DOMAIN:
            return domainConfig.ONE_TRUST_PROD_ID_NEW;

        default:
            return domainConfig.ONE_TRUST_STAGE_ID;
    }
}
