import config from '../../config';
import {
    COOKIE_FOR_ACCEPT_ONE_TRUST,
    COOKIE_FOR_SKIP_START_PAGE,
    LS_KEY_AWS_ID,
    URL_PARAM_AWS_ID,
    UUID_LS_KEY,
} from '../../constants';

export const getLinkHref = () => {
    const awsId = localStorage.getItem(LS_KEY_AWS_ID);
    const acceptCookie = localStorage.getItem(COOKIE_FOR_ACCEPT_ONE_TRUST);
    const uuid = localStorage.getItem(UUID_LS_KEY);

    const currentUrl = new URL(window.location.href);
    const nextUrl = new URL(config.WEB2APP_URL);

    for (let [key, value] of currentUrl.searchParams.entries()) {
        nextUrl.searchParams.set(key, value);
    }

    if (uuid) {
        nextUrl.searchParams.append(UUID_LS_KEY, uuid);
    }

    if (awsId) {
        nextUrl.searchParams.append(URL_PARAM_AWS_ID, awsId);
    }

    if (acceptCookie) {
        nextUrl.searchParams.append(COOKIE_FOR_ACCEPT_ONE_TRUST, acceptCookie);
    }

    nextUrl.searchParams.append(COOKIE_FOR_SKIP_START_PAGE, 'true');

    return nextUrl.href;
};
